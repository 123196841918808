.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.ow-anywhere {
  overflow-wrap: anywhere;
}

.fa-lg {
  color: #BA9765!important;;
}

.parallax {
  /* The image used */
  background-image: url("../public/assets/images/banhmi.jpg");

  /* Set a specific height */
  min-height: 1200px;
  height: 100%;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-device-width: 1366px) {
  .parallax {
    background-attachment: scroll;
    min-height: 600px;
  }
}

@media only screen and (max-device-width: 600px) {
  .container {
    padding-left: 0px!important;
    padding-right: 0px!important;
  }
}

.logo {
  max-width: 60%!important;
}

@media (max-width: 575px) {
  .container {
    width: 90%!important;
  }
  .titleText {
    font-size: 45px!important;
    color: #BA9765!important;
  }
}

.nav-link,.d-inline-block {
  color: #BA9765!important;
  font-size: 20px;
}

.button {
  background-color: #282c34;
  border: none;
  color: #BA9765;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 23px;
  margin: 4px 2px;
  width: 240px;
  cursor: pointer;
  border-radius: 8px;
}

.eatherePickup {
  font-size: 20px !important;
}

.titleText {
  font-size: 80px;
  color: #BA9765!important;
}

.adressText {
  font-size: 15px;
}

.button:hover {
  color: #fff;
  text-decoration: none;

}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #BA9765;
}

.App-link {
  color: #61dafb;
}

.highlight {
  background-color: yellow;
  color: red;
  padding: 10px;
  border: 2px solid red;
  text-align: center;
  font-weight: bold;
}

